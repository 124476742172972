export const ALL_LANGUAGES = [
  {
    language: 'Arabic',
    code: 'ar',
  },
  {
    language: 'Bulgarian',
    code: 'bg',
  },
  {
    language: 'Chinese (Simplified)',
    code: 'zh-Hans',
  },
  {
    language: 'Chinese (Traditional)',
    code: 'zh-Hant',
  },
  {
    language: 'Czech',
    code: 'cs',
  },
  {
    language: 'Danish',
    code: 'da',
  },
  {
    language: 'Dutch (Netherlands)',
    code: 'nl-NL',
  },
  {
    language: 'Finnish',
    code: 'fi',
  },
  {
    language: 'French (France)',
    code: 'fr',
  },
  {
    language: 'French (Canada)',
    code: 'fr-CA',
  },
  {
    language: 'German',
    code: 'de',
  },
  {
    language: 'Hungarian',
    code: 'hu',
  },
  {
    language: 'Indonesian (Indonesia)',
    code: 'id-ID',
  },
  {
    language: 'Italian',
    code: 'it-IT',
  },
  {
    language: 'Japanese',
    code: 'ja',
  },
  {
    language: 'Korean',
    code: 'ko',
  },
  {
    language: 'Norwegian Bokmål (Norway)',
    code: 'nb-NO',
  },
  {
    language: 'Polish (Poland)',
    code: 'pl-PL',
  },
  {
    language: 'Portuguese',
    code: 'pt',
  },
  {
    language: 'Portuguese (Brazil)',
    code: 'pt-BR',
  },
  {
    language: 'Romanian',
    code: 'ro',
  },
  {
    language: 'Russian',
    code: 'ru-RU',
  },
  {
    language: 'Spanish (Spain)',
    code: 'es-ES',
  },
  {
    language: 'Spanish (Mexico)',
    code: 'es',
  },
  {
    language: 'Swedish',
    code: 'sv',
  },
  {
    language: 'Thai',
    code: 'th-TH',
  },
  {
    language: 'Turkish',
    code: 'tr-TR',
  },
  {
    language: 'Vietnamese',
    code: 'vi',
  },
];
