import React, { useRef, useState, useCallback } from 'react';
import axios from 'axios';
import useFileUpload from 'react-use-file-upload';

export default function UploadFile({ onComplete }: { onComplete?: any }) {
  const [dragOver, setDragOver] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    files,
    fileNames,
    totalSize,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
    // fileTypes,
    // totalSizeInBytes,
  } = useFileUpload();

  const inputRef: any = useRef();

  const handDragOver = useCallback(
    (e) => {
      e.preventDefault();
      handleDragDropEvent(e);
      setDragOver(true);
    },
    [dragOver]
  );

  const handDrop = useCallback(
    (e) => {
      handleDragDropEvent(e);
      setFiles(e);
      setDragOver(false);

      setTimeout(() => {
        handleSubmit(e);
      }, 1500);
    },
    [dragOver]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (files?.length) {
        setLoading(true);

        const formData = createFormData();

        try {
          if (onComplete) {
            setTimeout(() => {
              onComplete();
            }, 3000);
          }

          const response = await axios.post(
            `/api/transcript/upload`,
            formData,
            {
              headers: {
                'content-type': 'multipart/form-data',
              },
            }
          );
          setLoading(false);
          clearAllFiles();
          if (onComplete) {
            onComplete(response?.data);
          }
        } catch (error) {
          alert(`Failed to submit files: ${JSON.stringify(error)}`);
          setLoading(false);
          clearAllFiles();
        }
      }
    },
    [setLoading, loading, files, onComplete]
  );

  return (
    <div className="flex flex-col items-center w-full">
      {/* Display the files to be uploaded */}
      {files?.length > 0 ? (
        <div className="flex flex-col items-center w-full">
          <div className="w-full bg-base-100 mx-4 p-5 flex flex-col justify-between h-[370px] rounded">
            <ul className="flex flex-col">
              {fileNames.map((name) => (
                <li
                  key={name}
                  className="py-1 flex justify-between items-center"
                >
                  <span>{name}</span>
                  <span onClick={() => removeFile(name)}>
                    <i className="fa fa-times text-lg hover:text-white cursor-pointer" />
                  </span>
                </li>
              ))}
            </ul>

            <div className="flex justify-between items-center w-full">
              <ul className="text-sm">
                <li>Total Size: {totalSize}</li>
              </ul>
              <li className="clear-all btn btn-sm btn-error text-white mx-4">
                <button onClick={() => clearAllFiles()}>Clear All</button>
              </li>
              <button
                onClick={!loading ? handleSubmit : () => console.log('')}
                className={`submit btn btn-info btn-sm text-white w-[200px] ${
                  loading || !files.length
                    ? 'opacity-10 cursor-default'
                    : 'cursor-pointer'
                }`}
                disabled={loading || !files.length}
                aria-disabled={loading}
                role="button"
              >
                {loading ? 'Loading...' : 'Upload'}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="form-container flex flex-col md:flex-row h-[370px] w-full">
          <div
            className={`w-full bg-base-100 h-[370px] p-2 flex flex-col justify-center items-center border border-gray-200 cursor-pointer rounded ${
              dragOver ? 'border-solid bg-info text-white' : 'border-dashed'
            }`}
            onDragEnterCapture={handDragOver}
            onDragOver={handDragOver}
            onDragLeaveCapture={() => dragOver && setDragOver(false)}
            onDrop={handDrop}
            onClick={() => inputRef.current.click()}
          >
            {dragOver ? (
              <p className="text-xl font-bold">Drop it like it's hot 🔥</p>
            ) : (
              <>
                <p className="text-xl font-bold">Drag & Drop File</p>

                <button className="mt-4">
                  Or{' '}
                  <span className="text-info underline">
                    select file to upload
                  </span>
                </button>

                {/* Hide the crappy looking default HTML input */}
                <input
                  ref={inputRef}
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e: any) => {
                    setFiles(e);
                    inputRef.current.value = null;
                  }}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
