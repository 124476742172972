import React, { useState, useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ALL_LANGUAGES } from '@/tools/constants/languages';

const DEFAULT_TAB = 'INFO';
const DEFAULT_LOCALE = 'English';

export default function TranscriptSlideout({
  activeItem,
  setActiveItem,
}: {
  activeItem: string;
  setActiveItem?: any;
}) {
  const [tab, setTab] = useState<string>(DEFAULT_TAB);
  const [language, setLanguage] = useState<string>(DEFAULT_LOCALE);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    isLoading: dataLoading,
    data: item,
    refetch,
  } = useQuery({
    enabled: !!activeItem,
    queryKey: [`transcriptItem:${activeItem}`],
    refetchInterval: 3000,
    queryFn: () =>
      fetch(`/api/transcript/fetch?id=${activeItem}`).then((res) => res.json()),
  });
  const languageCode = useMemo(
    () =>
      ALL_LANGUAGES?.find((locale: any) => locale.language === language)?.code,
    [language]
  );

  const sortedLanguageMemo = useMemo(
    () =>
      ALL_LANGUAGES?.sort((a: any, b: any) =>
        a.language.localeCompare(b.language)
      ),
    [item]
  );

  const transcriptItem = useMemo(
    () =>
      language === DEFAULT_LOCALE
        ? item?.transcript
        : item?.translations?.find(
            (locale: any) => locale.language === language
          ),
    [language, item?.transcript, item?.translations]
  );
  const transcript = transcriptItem?.transcript || transcriptItem;

  const renderTranscript = useCallback(() => {
    return loading ? (
      <div className="flex flex-col my-10 items-center">
        <div className="m-4">Loading...</div>
      </div>
    ) : transcript ? (
      <div className="flex flex-col">
        <div className="m-4">{transcript}</div>
      </div>
    ) : (
      !dataLoading && (
        <div className="flex flex-col my-10 items-center">
          <div className="m-4">No transcript available</div>
          <div className="m-4">
            <button
              className="btn btn-primary btn-sm text-white"
              onClick={() => reTranslateLanguage(language)}
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Translate'}
            </button>
          </div>
        </div>
      )
    );
  }, [language, loading, dataLoading, transcript]);

  const wait = (ms) => new Promise((res) => setTimeout(res, ms));

  const downloadVTTFile = useCallback(
    async (code: string) => {
      const translation = item?.translations?.find(
        (locale: any) => locale.code === code
      );
      let data = translation?.transcript || item?.transcript;
      const fileName = `${item.title}_${code}.vtt`;
      const metadata = {
        type: 'text/vtt',
      };

      // If data does not start with "WEBVTT" then add it to the beginning of the file
      if (!data.startsWith('WEBVTT')) {
        data = `WEBVTT\n\n${data}`;
      }

      const file = new File([data], fileName, metadata);
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      return Promise.resolve();
    },
    [item?.translations, item?.transcript, item?.title]
  );

  const downloadAllFiles = useCallback(async () => {
    for (const l of ALL_LANGUAGES) {
      await downloadVTTFile(l.code);
      await wait(300);
    }
  }, [downloadVTTFile]);

  const reTranslateLanguage = useCallback(
    async (language: string) => {
      setLoading(true);
      await fetch(
        `/api/translate/${item?.id}/${language}${
          transcriptItem ? `?transcriptId=${transcriptItem.id}` : ''
        }`
      );
      await refetch();
      setLoading(false);
    },
    [refetch, transcriptItem, item?.id]
  );

  return (
    <div className="drawer-side overflow-hidden">
      <div
        className="menu p-4 w-screen lg:w-80 bg-neutral text-white overflow-x-hidden"
        style={{ height: 'calc(100dvh - 55px)' }}
      >
        <div
          className="absolute top-2 left-2 text-xl font-bold"
          onClick={() => refetch()}
        >
          <i className="fa fa-refresh text-sm active:animate-spin active:text-primary cursor-pointer btn btn-ghost btn-xs flex justify-center items-center" />
        </div>
        <div
          className="absolute top-2 right-2 text-xl font-bold"
          onClick={() => setActiveItem(null)}
        >
          <i className="fa fa-xmark text-[18px] active:text-primary cursor-pointer btn btn-ghost btn-sm flex justify-center items-center" />
        </div>
        <div className="my-4">
          <div role="tablist" className="tabs tabs-boxed w-2/3 mx-auto">
            <a
              role="tab"
              className={`tab ${
                tab === DEFAULT_TAB && 'tab-active text-white'
              }`}
              onClick={() => setTab(DEFAULT_TAB)}
            >
              Info
            </a>
            <a
              role="tab"
              className={`tab ${
                tab === 'transcripts' && 'tab-active text-white'
              }`}
              onClick={() => setTab('transcripts')}
            >
              Transcripts
            </a>
          </div>
          {tab === DEFAULT_TAB ? (
            <div>
              <div className="flex flex-col mt-4">
                <div className="flex flex-col">
                  <div className="text-lg">
                    <span className="font-bold mr-1">Title:</span> {item?.title}
                  </div>
                  <div className="text-sm ml-2 mt-2">
                    <span className="font-bold mr-1">File Type:</span>{' '}
                    {item?.fileType}
                  </div>
                  <div className={`text-sm ml-2 mt-2`}>
                    <span className="font-bold mr-1">Status:</span>
                    <span
                      className={`${
                        item?.status === 'PENDING'
                          ? 'text-warning'
                          : item?.status === 'ERROR'
                          ? 'text-error'
                          : 'text-success'
                      }`}
                    >
                      {item?.status}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col mt-4">
                  <div className="text-lg font-bold">Description</div>
                  <div className="text-sm ml-2 mt-1">{item?.description}</div>
                </div>
                {!!item?.summary?.length && (
                  <div className="flex flex-col mt-4">
                    <div className="text-lg font-bold">Summary</div>
                    <div className="text-sm ml-2 mt-1">{item?.summary}</div>
                  </div>
                )}
                {!!item?.keyPoints?.length && (
                  <div className="flex flex-col mt-4">
                    <div className="text-lg font-bold">Key Points</div>
                    <div className="text-sm ml-2 mt-1">
                      {item?.keyPoints?.map((point: string, index) => (
                        <div className="flex items-center" key={index}>
                          • {point}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {!!item?.quotes?.length && (
                  <div className="flex flex-col mt-4">
                    <div className="text-lg font-bold">Quotes</div>
                    <div className="text-sm ml-2 mt-1">
                      {item?.quotes?.map(({ quote }, index) => (
                        <div className="flex items-center" key={index}>
                          • "{quote}"
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {!!item?.questions?.length && (
                  <div className="flex flex-col mt-4">
                    <div className="text-lg font-bold">Questions</div>
                    <div className="text-sm ml-2 mt-1">
                      {item?.questions?.map((question: string, index) => (
                        <div className="flex items-center" key={index}>
                          • {question}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="w-full flex justify-start items-center mt-4">
                <div className="dropdown flex">
                  <div
                    tabIndex={0}
                    role="button"
                    className="btn btn-sm text-xs w-64"
                  >
                    {language}
                  </div>
                  <ul
                    tabIndex={0}
                    className="dropdown-content z-[1] menu shadow bg-base-100 text-neutral dark:text-white text-xs rounded-box w-64 overflow-x-hidden"
                  >
                    <li
                      className={`${
                        language === 'English' ? 'bg-primary/20 rounded' : ''
                      }`}
                    >
                      <a onClick={() => setLanguage('English')}>English</a>
                    </li>
                    {sortedLanguageMemo?.map((locale, index) => {
                      return (
                        <li
                          key={index}
                          className={`${
                            language === locale.language
                              ? 'bg-primary/20 rounded'
                              : ''
                          }`}
                        >
                          <a onClick={() => setLanguage(locale.language)}>
                            {locale.language}
                            <span>
                              {item?.translations?.find(
                                (l) => l.code === locale.code
                              )
                                ? ''
                                : '*'}
                            </span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {language !== 'English' && transcript && (
                  <button
                    className="btn btn-primary btn-sm text-white w-16 ml-2"
                    onClick={() => reTranslateLanguage(language)}
                    disabled={loading}
                  >
                    Re-Translate
                  </button>
                )}
                {transcript && (
                  <button
                    className="btn btn-primary btn-sm text-white w-16 ml-2"
                    onClick={() =>
                      language === 'English'
                        ? downloadAllFiles()
                        : downloadVTTFile(languageCode)
                    }
                    disabled={loading}
                  >
                    {language === 'English' ? 'Download All' : 'Download'}
                  </button>
                )}
              </div>
              <div className="whitespace-pre-line">{renderTranscript()}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
