import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useUser, withPageAuthRequired } from '@auth0/nextjs-auth0/client';
import { HttpBadRequest } from '@httpx/exception';
import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useQuery } from '@tanstack/react-query';

import { getServerTranslations } from '@/tools/lib/i18n';

import { homeConfig } from '@/tools/views/home/home.config';
import { UploadForm, TranscriptSlideout } from '@/tools/components';

const AdminLayout = dynamic(
  () => import('@livingsecurity/encode/components/templates/AdminLayout'),
  {
    ssr: false,
  }
);

export default withPageAuthRequired(function TranscriptRoute(
  props: InferGetServerSidePropsType<any>
) {
  const AdminLayoutComponent = AdminLayout as any;
  const modalRef: any = useRef();
  const [activeItem, setActiveItem] = useState<string>(null);
  const [activeStatus, setStatus] = useState<string>('ACTIVE');
  const [limit, setLimit] = useState<number>(20);
  const [start, setStart] = useState<string>(null);
  const { user, isLoading } = useUser();
  const { data, refetch } = useQuery({
    queryKey: ['transcripts'],
    refetchInterval: 3000,
    queryFn: () =>
      fetch(
        `/api/transcript/fetch?archived=${
          activeStatus === 'ARCHIVED'
        }&start=${start}&limit=${limit}`
      ).then((res) => res.json()),
  });

  useEffect(() => {
    refetch();
  }, [activeStatus]);

  const toggleStatus = useCallback(
    (status) => {
      setStatus(status);
    },
    [refetch, setStatus]
  );

  const updateStatus = useCallback(
    async (e, id) => {
      e.stopPropagation();

      await fetch(
        `/api/transcript/archive?id=${id}&status=${
          activeStatus === 'ARCHIVED' ? 'SUCCESS' : 'ARCHIVED'
        }`
      ).then((res) => res.json());

      refetch();
    },
    [refetch, setStatus, activeStatus]
  );

  const renderHeader = (column: any) => (
    <tr>
      {column.map((item: any, index) => (
        <th key={index}>{item}</th>
      ))}
    </tr>
  );
  const renderRow = useCallback(
    (row: any, id: string) => (
      <tr
        key={id}
        className={`text-xs md:text-md cursor-pointer ${
          activeItem === id ? 'bg-primary/20' : 'hover:bg-primary/20'
        }`}
        onClick={() => setActiveItem(id)}
      >
        {row.map((item: any, index) => (
          <td key={index}>{item}</td>
        ))}
        <td>
          {activeStatus === 'ARCHIVED' ? (
            <i
              className="fas fa-arrow-rotate-right text-neutral dark:text-white hover:text-primary dark:hover:text-primary cursor-pointer"
              onClick={(e) => updateStatus(e, id)}
            />
          ) : (
            <i
              className="fas fa-trash text-neutral dark:text-white hover:text-primary dark:hover:text-primary cursor-pointer"
              onClick={(e) => updateStatus(e, id)}
            />
          )}
        </td>
      </tr>
    ),
    [activeItem, activeStatus]
  );

  const NavItems = () => {
    return (
      <div className="flex items-center justify-around text-white w-full">
        <Link
          href="/transcripts"
          aria-label="Transcription Tool"
          data-id="Transcription Tool"
        >
          <button className="btn btn-xs btn-ghost mx-2">
            Transcripts (VTT)
          </button>
        </Link>
        <Link
          href="/translate"
          aria-label="Translation Tool"
          data-id="Translation Tool"
        >
          <button className="btn btn-xs btn-ghost mx-2">Translate Text</button>
        </Link>
      </div>
    );
  };

  return (
    <AdminLayoutComponent
      user={user}
      productName="Tools"
      hideSubnav
      hideChat
      hideAppSwitcher
      isLoading={isLoading}
    >
      <div
        className={`w-full drawer drawer-end ${activeItem && 'drawer-open'}`}
      >
        <input
          id="item-drawer"
          type="checkbox"
          className="drawer-toggle"
          onClick={() => setActiveItem(null)}
        />
        <div className="drawer-content flex flex-col items-center justify-start w-full mt-5">
          <div className="flex flex-col w-full items-start">
            <div className="flex justify-between w-full p-2">
              <div
                role="tablist"
                className="tabs tabs-boxed w-contain h-[42px] mx-auto bg-base-200/30"
              >
                <a
                  role="tab"
                  className={`tab ${
                    activeStatus === 'ACTIVE' && 'tab-active text-white'
                  }`}
                  onClick={() => toggleStatus('ACTIVE')}
                >
                  Active
                </a>
                <a
                  role="tab"
                  className={`tab ${
                    activeStatus === 'ARCHIVED' && 'tab-active text-white'
                  }`}
                  onClick={() => toggleStatus('ARCHIVED')}
                >
                  Archived
                </a>
              </div>
              <div className={`w-full flex pr-4 justify-end`}>
                <button
                  className="btn btn-primary text-white"
                  onClick={() => {
                    /* @ts-ignore */
                    document.getElementById('upload-modal').showModal();
                  }}
                >
                  Upload
                </button>
              </div>
            </div>
            {!!data?.items?.length ? (
              <div className="flex items-start w-full min-h-full py-2">
                <div className="overflow-x-auto w-full mt-2">
                  <table className="table">
                    <thead>
                      {renderHeader(['Name', 'Type', 'File Type', 'Status'])}
                    </thead>
                    <tbody>
                      {data?.items?.map((item: any) => {
                        return renderRow(
                          [item.title, item.type, item.fileType, item.status],
                          item.id
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="flex justify-center w-full min-h-full py-2">
                <div className="overflow-x-auto w-full mt-20 text-center">
                  No Items Found!
                </div>
              </div>
            )}
          </div>
        </div>
        <TranscriptSlideout
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        />
      </div>
      <dialog id="upload-modal" className="modal" ref={modalRef}>
        <div className="modal-box h-[420px] w-full lg:min-w-[800px]">
          <UploadForm
            onComplete={() => {
              document.getElementById('close-btn').click();
              refetch();
            }}
          />
        </div>
        <form method="dialog" className="modal-backdrop">
          <button id="close-btn">close</button>
        </form>
      </dialog>
    </AdminLayoutComponent>
  );
});

// export const getStaticProps: GetStaticProps = async (context) => {
export const getServerSideProps: GetServerSideProps = async (context) => {
  const { locale } = context;
  if (locale === undefined) {
    throw new HttpBadRequest('locale is missing');
  }
  const { i18nNamespaces } = homeConfig;

  return {
    props: {
      ...(await getServerTranslations(locale, i18nNamespaces)),
    },
  };
};
